import Inputmask from "inputmask";
import autocomplete from 'autocompleter';
import moment from 'moment';
import { load } from 'recaptcha-v3';

var recaptcha_loader = load("6LdDg2UcAAAAAND_CUELeDK9xHfFLiaVpwVjElus")

var cpfMask = new Inputmask("999.999.999-99");
cpfMask.mask(document.querySelectorAll("input[name=cpf]"));
var birthdateMask = new Inputmask("99/99/9999");
birthdateMask.mask(document.querySelectorAll("input[name=data_de_nascimento]"));

var games = [
  { label: 'Free Fire', value: 'freefire' },
  { label: 'Valorant', value: 'Valorant' },
  { label: 'Counter-Strike', value: 'Counter-Strike' },
  { label: 'Grand Theft Auto V', value: 'Grand Theft Auto V' },
  { label: 'Lost Ark', value: 'Lost Ark' },
  { label: 'Call of Duty', value: 'Call of Duty' },
  { label: 'Warzone', value: 'Warzone' },
  { label: 'Minecraft', value: 'Minecraft' },
  { label: 'Rainbow Six', value: 'Rainbow Six' },
  { label: 'Pubg', value: 'Pubg' },
  { label: 'Fifa', value: 'Fifa' },
  { label: 'Rocket League', value: 'Rocket League' },
  { label: 'Dota', value: 'Dota' },
  { label: 'Minecraft', value: 'Minecraft' },
  { label: 'Apex Legends', value: 'Apex Legends' },
  { label: 'Battlefielde', value: 'Battlefielde' },
  { label: 'Dead by Daylight', value: 'Dead by Daylight' },
  { label: 'Diablo', value: 'Diablo' },
  { label: 'Overwatch', value: 'Overwatch' },
  { label: 'World of Warcraft', value: 'World of Warcraft' },
  { label: 'Teamfight Tactics', value: 'Teamfight Tactics' },
  { label: 'Smite', value: 'Smite' },
  { label: 'Paladins', value: 'Paladins' },
  { label: 'New World', value: 'New World' },
  { label: 'Poker', value: 'Poker' },
  { label: 'Grand Chase', value: 'Grand Chase' },
  { label: 'Pokemon Arceus', value: 'Pokemon Arceus' },
  { label: 'Super Mario Odyssey', value: 'Super Mario Odyssey' },
  { label: 'Zelda: Breath of the Wild', value: 'Zelda: Breath of the Wild' },
  { label: 'Animal Crossing', value: 'Animal Crossing' },
  { label: 'Super Smash Bros: Ultimate', value: 'Super Smash Bros: Ultimate' },
  { label: 'Mario Kart 8', value: 'Mario Kart 8' },
  { label: 'Pokemon Sword and Shield', value: 'Pokemon Sword and Shield' },
  { label: 'Mansão do Luigi 3', value: 'Mansão do Luigi 3' },
  { label: 'Fire Emblem', value: 'Fire Emblem' },
  { label: 'Celeste', value: 'Celeste' },
  { label: 'Cuphead', value: 'Cuphead' },
  { label: 'Super Mario Maker 2', value: 'Super Mario Maker 2' },
  { label: 'Cyberpunk 2077', value: 'Cyberpunk 2077' },
  { label: 'Assassin’s Creed Valhalla', value: 'Assassin’s Creed Valhalla' },
  { label: 'Far Cry 6', value: 'Far Cry 6' },
  { label: 'The Witcher 3', value: 'The Witcher 3' },
  { label: 'Avenger', value: 'Avenger' },
  { label: 'Marvel’s Guardians of the Galaxy', value: 'Marvel’s Guardians of the Galaxy' },
  { label: 'Gears of War 5', value: 'Gears of War 5' },
  { label: 'Halo Infinite', value: 'Halo Infinite' },
  { label: 'Forza Horizon 5', value: 'Forza Horizon 5' },
  { label: 'Fortnite', value: 'Fortnite' },
  { label: 'Star Wars Jedi Fallen Order', value: 'Star Wars Jedi Fallen Order' },
  { label: 'God of War', value: 'God of War' },
  { label: 'Spider-Man', value: 'Spider-Man' },
  { label: 'Horizon Zero Dawn', value: 'Horizon Zero Dawn' },
  { label: 'The Last of Us', value: 'The Last of Us' },
  { label: 'Ghost of Tsushima', value: 'Ghost of Tsushima' },
  { label: 'Uncharted', value: 'Uncharted' },
  { label: 'Dark Souls', value: 'Dark Souls' },
  { label: 'Days Gone', value: 'Days Gone' },
  { label: 'Death Stranding', value: 'Death Stranding' },
  { label: 'Elder Ring', value: 'Elder Ring' },
  { label: 'Skyrim', value: 'Skyrim' },
  { label: 'Mortal Kombat 11', value: 'Mortal Kombat 11' },
  { label: 'Detroit Become Human', value: 'Detroit Become Human' },
  { label: 'Tom Clancy’s', value: 'Tom Clancy’s' },
  { label: 'Doom Eternal', value: 'Doom Eternal' },
  { label: 'Final Fantasy', value: 'Final Fantasy' },
  { label: 'Resident Evil', value: 'Resident Evil' },
  { label: 'Call of Duty Mobile', value: 'Call of Duty Mobile' },
  { label: 'League of Legends Wild Rift', value: 'League of Legends Wild Rift' },
  { label: 'Pokemon Go', value: 'Pokemon Go' },
  { label: 'Clash Royale', value: 'Clash Royale' },
  { label: 'Clash of Clans', value: 'Clash of Clans' },
  { label: 'PUBG Mobile', value: 'PUBG Mobile' },
  { label: 'Arena of Valor', value: 'Arena of Valor' },
  { label: 'Brawl Stars', value: 'Brawl Stars' },
  { label: 'Arena of Valor', value: 'Arena of Valor' },
  { label: 'Among Us', value: 'Among Us' },
  { label: 'Genshin Impact', value: 'Genshin Impact' },
  { label: 'Candy Crush', value: 'Candy Crush' },
  { label: 'Mobile Legends', value: 'Mobile Legends' },
  { label: 'Pokemon Unite', value: 'Pokemon Unite' },
  { label: 'Fifa Mobile', value: 'Fifa Mobile' },
  { label: 'Hearthstone', value: 'Hearthstone' },
  { label: 'Outro', value: 'Outro' },
  { label: 'Nenhum', value: 'Nenhum' },
  { label: 'Não Jogo', value: 'Não Jogo' },
  { label: 'League of Legends', value: 'League of Legends' }
];


var inputs = ["game-mobile", "game-desktop"];

inputs.forEach(input => {
  var input = document.getElementById(input);

  autocomplete({
    input: input,
    fetch: function(text, update) {
      text = text.toLowerCase();
      var suggestions = games.filter(n => n.label.toLowerCase().startsWith(text))
      update(suggestions);
    },
    onSelect: function(item) {
      input.value = item.label;
    }
  });
});

function isCpf(cpf) {
  var exp = /\.|-|_/g;
  cpf = cpf.toString().replace(exp, "");
  if(cpf == '') return false;
  // Elimina CPFs invalidos conhecidos
  if (cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999")
    return false;
    // Valida 1o digito
    var add = 0;
    for (var i=0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
    var rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
    rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
    return false;
    // Valida 2o digito
    add = 0;
    for (var i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
    rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
    return false;
    return true;
  }

  function isCpfFormatted(cpf) {
    var validCPF = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
    return cpf.match(validCPF);
  }

  (function ($) {
    $.validator.addMethod("cpf", function (value, element, type) {
      if (value === "")
      return true;

      if ((type === 'format' || type === 'both') && !isCpfFormatted(value)) {
        return false;
      }

      return ((type === 'valid' || type === 'both')) ? isCpf(value) : true;
    }, function (type, element) {
      return (type === 'format' || (type === 'both' && !isCpfFormatted($(element).val()))) ? 'Formato do CPF n&atilde;o &eacute; v&aacute;lido' : 'Por favor digite um CPF válido';
    });

    jQuery.validator.addMethod("validDate", function(value, element) {
      return this.optional(element) || moment(value, "DD/MM/YYYY").isValid();
    }, "Informe uma data válida");
  })(jQuery);

  var forms = {"#form-mobile": "#output-mobile", "#form-desktop": "#output-desktop"};

  Object.keys(forms).forEach(_form => {
    jQuery(_form).validate({
      rules: {
        nome_completo: "required",
        qual_seu_jogo_favorito_: "required",
        email: {
          required: true,
          email: true
        },
        cpf: {
          cpf: "valid",
        },
        nome_completo: "required",
        data_de_nascimento: "validDate"
      },
      messages: {
        nome_completo: "Informe seu nome completo",
        email: "Informe seu e-mail",
        qual_seu_jogo_favorito_: "Informe seu jogo favorito",
        data_de_nascimento: "Informe sua data de nascimento",
        cpf: "Informe um CPF válido",
      },
      submitHandler: function(form) {
        const formData = jQuery(form).serializeArray()
        let data = {}
        formData.forEach(_data => data[_data.name] = _data.value)
        recaptcha_loader.then((recaptcha) => {
          recaptcha.execute("leads").then((token) => {
            $.ajax({
              url: "https://martechapps.cloud.itau.com.br/clientespotenciais",
              type: "POST",
              contentType: "application/json",
              data: JSON.stringify({
                ...data,
                "recaptchaToken": token,
                "id_campanha": "blt3f56f181099f9e6b"
              }),
              headers: {
                "x-itau-apikey": "bltaef1200413a7ff7c",
                "x-itau-correlationID": "csf936889a835e6f60037e0920",
                "Content-Type": "application/json",
              },
              dataType: "json",
              success: function () {
                jQuery(forms[_form]).removeClass().addClass("success").html("Tudo certo! Você foi cadastrado com sucesso!");
                jQuery(_form).fadeOut();
                jQuery(_form).prev().fadeIn();
              },
              error: function (response) {
                let message = "<ul>";
                response.responseJSON.errors.forEach(error => message = message+"<li>"+error.message+"</li>")
                jQuery(forms[_form]).removeClass().addClass("error").html(message+"</ul>");
              }
            });
          })
        })
      }
    });
  })


  function textoanimacao1(index = 0) {
    var frases = [
      // '<h6 id="title_1_h6">Feito para</h6><h6 id="title_2_h6">todes.</h6>',
      '<h6 id="title_1_h6">O banco que</h6><h6 id="title_2_h6">joga junto.</h6>',
    ]

    index = !frases.hasOwnProperty(index) ? 0 : index
    var frase = frases[index]

    var container = document.getElementById('animacao-texto')
    container.innerHTML = '';

    var html_string = '<div class="c-rotating-text__wrap css-typing">'+frase+'</div>'

    var div = document.createElement('div');
    div.innerHTML = html_string.trim();

    setTimeout(() => container.appendChild(div), 1000);


    setTimeout(() => textoanimacao1(index + 1), 6000);
  }

//   setTimeout(textoanimacao1, 6000);


  // setTimeout(function run() {
  //   document.getElementById("title_1_h6").innerHTML = "texto 1";

  //   setTimeout(run, 100);
  // }, 100);
  // ;

  jQuery("#send-form-mobile").on("click", function() {
    jQuery("#form-mobile").submit()
  })
